.showFilesButton {
  margin-left: 10px;
  background-color: #046a38 !important;
  border: none !important;
  width: 180px;
  height: 50px;
}

.showFilesButton:hover {
  opacity: 85% !important;
}

.uploadPageContent {
  display: flex;
  width: 1000px;
}

.cancel-btn {
  margin-left: 30px;
  background-color: white !important;
  color: green !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.cancel-btn:hover {
  background-color: lightgrey !important;
}

.upload-file-button {
  background-color: #26890d !important;
  border-color: #26890d !important;
  border-radius: 10px !important;
}
.upload-file-button:hover {
  opacity: 85%;
}

.btn-close{
    border: 2px solid white;
}

.btn-close:hover{
    border: 2px solid #046a38 !important;
}

.formInput{
    margin-left: 10px !important;
    border: 2px solid #26890d !important;
}

.formLabel{
width: 135px !important;
}

.formGroup{
    display:flex;
    margin-bottom: 20px;
}

.form-control{
    resize: none !important;
    margin-right:10px;
}

.dropdown-toggle {
    background-color: #046a38 !important;
    border: none !important;
    width: 260px !important;
    height: 40px !important;
}

.dropdown-toggle:hover{
    opacity: 85%;
}

.dropdown-item:hover{
    background-color: #26890d !important;
    color: white !important;
}