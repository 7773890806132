.details-wrapper {
    background-color: #D9D9D9;
    border-radius: 33px;
    width: 80%;
    margin: auto;
    padding-top: 1rem;
    margin-top: 1.75rem;
}

.details-wrapper h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 2rem;
}

.details-wrapper hr{
    background-color: #F5F5F5;
    margin:0px;
    color:#D9D9D9;
    border-top:2px solid;
}

.nav-tabs .nav-item button {
    border-radius: 33px 33px 0px 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color:#808080;
    
}

.nav-tabs .nav-item button.active {
    color: #007680;
    background-color: #F5F5F5;
    border-color: #F5F5F5;
}

.tab-wrapper{
    background-color: #F5F5F5;
}