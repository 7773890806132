tr.selectable:hover{
  cursor: pointer;
}

.table-header {
    background: rgb(134,188,37);
    font-weight: bold;
    padding: 8px;

  }
