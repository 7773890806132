.points-table.full {
    margin-top: 0;
    padding: 8px;
}

 
.points-table tbody tr {
    background-color: #f5f5f5; /* Light grey background for rows */
}

.points-table tbody tr td,
.points-table thead tr th {
    border: 1px solid #ddd; /* Subtle border for table cells */
    padding: 8px;
}

/* Styles for editable fields */
.points-table input, .points-table textarea {
    width: 100%;
    min-height: 12rem; /* Changed from height to min-height for flexibility */
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.points-table textarea {
    resize: vertical; /* Allows vertical resizing */
}

/* Button and icon styling */
.points-table button {
    cursor: pointer;
    background: #86BC25; /* Primary button color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px; /* Adjusted padding for better appearance */
    font-size: 1rem; /* Adjusted font size for readability */
}

.points-table button:hover {
    opacity: 0.8; /* Slightly more noticeable hover effect */
}

.actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.action-button {
    margin: 0 5px; /* Space between buttons */
    cursor: pointer;
    background-color: transparent; /* Transparent background for icon buttons */
    border: none;
}

.action-button i.fa {
    color: white; /* White icons to match button text color */
}

/* Additional styling for the save button */
.save-button {
    background: #4CAF50; /* Distinct green color for the save button */
    color: white; /* White text color */
    padding: 10px 15px; /* Slightly larger padding for emphasis */
    font-weight: bold; /* Bold text for emphasis */
    margin: 0 auto; /* Center the button in its container */
    display: block; /* Block display to allow auto margins to center */
}

/* Adjusting edit button specifically if needed */
.edit-btn {
    background-color: #007BFF; /* Standard blue for edit */
    color: white;
}
