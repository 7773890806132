/* App.css */
.app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;

    .navigation-bar {
        background-color: black;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 20px 0px 20px;
        position: relative;

        .deloitte-header {
            padding: 1vh;
            margin-bottom: 3vh;
            margin-top: 0.5vh;
            top: 3;
            color: white;
            font-weight: bold;
        }

        .main-navbar {
            background-color: black;

            .color-navItem {
                color: white;
                font-weight: bold;
            }

            .lang-dropdown .nav-link {
                color: #ffffff;
            }

            .nav-item .dropdown-menu {
                background: #000000;
            }

            .nav-item .dropdown-item {
                color: #ffffff;
            }

            .nav-item .dropdown-item:hover {
                background: #86BC25;
            }

            .navbar-toggler {
                .navbar-toggler-icon {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
}





.image-container {
    position: relative;
    margin-top: 2vh;
    padding-bottom: 2vh;

    .centered-image {
        display: block;
        height: 60vh;
        margin: 0 auto;
    }
}

.project-modal{
    background-color: black;
    color: white !important;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
}

.navHover:hover{
    color: #66ff66 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    text-decoration: underline;
}

.text-content {
    text-align: center;
}

.simple-green-bar {
    width: 100%;
    height: 5%;
    padding: 10px;
    color: #ffffff;
    position: fixed;
    bottom: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #86BC25;

}


.deloitteLogo {
    margin-top: -9px;
    height:26px;
}

.deloitte-header {
    vertical-align: center;
}