.assessment-button {
  background-color: #43b02a !important;
  border: 1px solid #43b02a !important;
  color: #fff !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  outline: none !important;
  outline: 0 !important;
  padding: 8px 16px !important;
  text-align: center !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0 !important;
  box-sizing: border-box !important;
  transition: transform 150ms, box-shadow 150ms, color 100ms !important;
}

.assessment-button:hover {
  color: rgb(22, 22, 22) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0 !important;
  transform: translateY(-2px) !important;
}

/* HTML: <div class="loader"></div> */
.threeDotsLoader {
  width: 20px;
  opacity:75%;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#000 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}