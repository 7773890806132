
table{
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid #DDD;
  margin:8px;
}
td {
  padding: 8px;
  text-align: left;

}

tr.selectable:hover {
  background-color: #DDD;
  cursor: pointer;
}

th {
  padding: 8px;
  text-align: left;
  background-color: #EEE;

    border-bottom: 1px solid #DDD;
  
}


th:first-of-type {
  border-top-left-radius: 10px;
}
th:last-of-type {
  border-top-right-radius: 10px;
}
tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
}
tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}



/* Apply a border to the right of all but the last column */

table td:not(:last-child) {
 border-right: 1px solid #DDD;
}

/* Apply a border to the bottom of all but the last row */
table>thead>tr:not(:last-child)>th,
table>thead>tr:not(:last-child)>td,
table>tbody>tr:not(:last-child)>th,
table>tbody>tr:not(:last-child)>td,
table>tfoot>tr:not(:last-child)>th,
table>tfoot>tr:not(:last-child)>td,
table>tr:not(:last-child)>td,
table>tr:not(:last-child)>th,
table>thead:not(:last-child),
table>tbody:not(:last-child),
table>tfoot:not(:last-child) {
 border-bottom: 1px solid #DDD;
}