.cardWrap {
    position: relative !important;
    margin: 10px !important;
    width: 235px !important;
    height: 100px !important;
    background-color: #26890D !important;
    cursor: pointer !important;
    box-shadow: 0px 5px 10px 0px #2C5234 !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.cardWrap.selected {
    background-color: #2C5234 !important;
  }

.cardWrap:hover {
    transform: translateY(-5px) !important;
    box-shadow: 0px 10px 20px 2px #2C5234 !important;
}
  
.iconContainer {
    position: absolute;
    bottom: 0;
    right: 0;
}
  
.principalContent{
    color: white;
    font-weight: bold;
    margin: 3px;
}

.csvUploadIcon{
    position: absolute;
    top: 0;
    right: 0;
}