/* AssessmentList.css */

.assessment-list-container {
  /* Add any styles for the outer container as needed */
  margin-top :3rem;
}

.category-container {
  width: 100%;
  display: flex;
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically within the container */
  margin-bottom: 2.4rem; /* Add margin for better spacing */
  
 }

.category-label {
  /* Add any specific styles for the category label */
  /* Adjust the margin as needed for spacing between label and elements */
  font-weight: bold;
  font-size: 18px;
  background-color: #43b02a;
  padding:3rem 1.1rem;
  rotate: 180deg;
  writing-mode: vertical-lr;
  color: white;
}

.elements-container {
  /* Add any styles for the container of elements */
  display: flex;
  flex-direction: column;
  width:100%;
  /* Align items in a column */
}

.textfield {
  width: calc(100% - 40px);
  height: 140px;
  margin-right: 30px;
  resize: none;
}

.textfield:focus{
  outline: 2px  solid   #43b02a;
}

.title {
  display: flex;
  font-size: 18px;
}

.textfield-wrapper{
  display: flex;
  margin-bottom: 1rem;
}

.invisible-button{
  background: none;
  border: none
}

.buttons-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: end;
}